$(document).ready(function () {
    const nav = $('nav');
    const brandImg = $('.navbar-brand img');

    let scroll_start = 0;
    const offset = 80;

    $(document).scroll(function () {
        scroll_start = $(this).scrollTop();
        if (scroll_start > offset) {
            nav.addClass('scrolled');
        } else {
            nav.removeClass('scrolled');
        }
    });
});
